export class Component {
  constructor(config, parentComponent) {
    this.config = config
    this.children = []
    this.type = config.type
    this.params = config.params || {}
    this.meta = config.meta || {}
    if (parentComponent) {
      parentComponent.children.push(this)
    }
  }

  mounted($el) {
    this.$el = $el
  }

  values() {
    if (!this.$el) {
      return {}
    }
    const values = {}
    for (const input of this.$el.querySelectorAll('input, textarea')) {
      if (input.name) {
        values[input.name] = input.value
      }
    }
    return values
  }

  /**
   * Get the template file name.
   *
   * @returns The file name of the template to render this component.
   */
  templateName() {
    return this.type
  }

  /**
   * Get the templates needed by this component and its child-components.
   *
   * @returns An set containing all the required template names.
   */
  neededTemplates() {
    const templates = new Set()
    templates.add(this.templateName())
    for (const child of this.children) {
      child.neededTemplates().forEach((template) => templates.add(template))
    }
    return templates
  }

  /**
   * Generate the v-scope object to pass to the template.
   *
   * @returns The v-scope available to the template.
   */
  vueScope() {
    return {
      component: this,
      type: this.type,
      params: this.params,
      meta: this.meta,
      children: this.children,
    }
  }

  /**
   * Set an element’s attributes based on the component‘s parameters.
   *
   * Usually this is invoked as v-effect in a vue template.
   *
   * @param {HTMLElement} $el The element which’s attributes should be set.
   * @param {String[]} exclude Attribute names that should be excluded.
   */
  attributesFromParams($el, exclude = []) {
    Object.keys(this.params).forEach(attribute => {
      if (attribute in $el && !exclude.includes(attribute)) {
        $el[attribute] = this.params[attribute]
      }
    })
    // Copy dataset to set data-attributes as well.
    Object.keys(this.params.dataset || {}).forEach(key => {
      $el.dataset[key] = this.params.dataset[key]
    })
  }
}
